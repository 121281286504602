import React, { Component } from 'react';
import Banner from './Banner';
import Project from './Project';

class Projects extends Component {
	render() {
		return (
			<div className="Projects">
				<Banner />
				<div className="Projects-loop">
					<div className="container">
						<div className="row">
							<div className="col-lg-3">
								<h2>Projects</h2>
							</div>
							<div className="col-lg-9">
								<Project />
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default Projects;