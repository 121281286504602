import React, { Component } from 'react';
import Banner from './Banner';
import Content from './Content';
import './About.css';

class About extends Component {
	render() {
		return (
			<div className="About">
				<Banner />
				<Content />
			</div>
		);
	}
}

export default About;