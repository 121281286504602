import React, { Component } from "react";
import Banner from "./Banner";
import "./Single.css";

class Single extends Component {
    render() {
        return (
            <div className="Single">
                <Banner id="cocktail" />
                <div className="Content">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-3 Content-title">
                                <h2>Cocktail Recipe Finder Web App</h2>
                                <a
                                    href="https://github.com/ArelySkywalker/Six-Gummy-Bears-and-some-Scotch"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="App-button"
                                >
                                    View on Github
                                </a>
                                <a
                                    href="https://gummy-bears-and-some-scotch.firebaseapp.com"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="App-button"
                                >
                                    View App
                                </a>
                            </div>
                            <div className="col-lg-9 Content-text">
                                <p>
                                    A dynamic web application built to help
                                    users discover and filter cocktail recipes
                                    with ease. The app integrates the CocktailDB
                                    API to provide users with a wide range of
                                    drink options based on their preferences.
                                </p>

                                <h3>Key Features:</h3>
                                <p>
                                    <ul>
                                        <li>
                                            <strong>Recipe Listings:</strong>{" "}
                                            Users can browse through an
                                            extensive collection of cocktails,
                                            with detailed ingredients and
                                            instructions for each drink.
                                        </li>
                                        <li>
                                            <strong>Advanced Filtering:</strong>{" "}
                                            Allows users to filter cocktails
                                            based on categories like
                                            ingredients, alcoholic/non-alcoholic
                                            options, and more.
                                        </li>
                                        <li>
                                            <strong>
                                                Search Functionality:
                                            </strong>{" "}
                                            Users can search for specific
                                            cocktails by name or ingredient,
                                            offering a fast and intuitive user
                                            experience.
                                        </li>
                                    </ul>
                                </p>
                                <h3>Technologies Used:</h3>
                                <p>
                                    <ul>
                                        <li>
                                            <strong>Frontend:</strong> Built
                                            with React, leveraging its
                                            component-based architecture for
                                            seamless UI updates and a smooth
                                            user experience.
                                        </li>
                                        <li>
                                            <strong>API Integration:</strong>{" "}
                                            Utilizes the CocktailDB API to
                                            dynamically fetch and display
                                            cocktail data.
                                        </li>
                                        <li>
                                            <strong>State Management:</strong>{" "}
                                            Implemented React Hooks and Context
                                            API for efficient state management
                                            across the application.
                                        </li>
                                        <li>
                                            <strong>UI Styling:</strong> Styled
                                            using CSS/Sass, focusing on a clean
                                            and modern interface.
                                        </li>
                                        <li>
                                            <strong>Deployment:</strong> Hosted
                                            on Firebase for a scalable and
                                            reliable web hosting solution.
                                        </li>
                                    </ul>
                                </p>
                                <h3>Challenges & Solutions:</h3>
                                <p>
                                    <ul>
                                        <li>
                                            <strong>
                                                Optimizing API Calls:
                                            </strong>{" "}
                                            Reduced API call redundancy by
                                            implementing efficient state
                                            management, allowing the app to
                                            cache data and reduce loading times.
                                        </li>
                                        <li>
                                            <strong>User Experience:</strong>{" "}
                                            Focused on creating an intuitive
                                            filtering and search mechanism,
                                            ensuring users can easily navigate
                                            through hundreds of cocktail
                                            recipes.
                                        </li>
                                    </ul>
                                </p>
                                <br />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Single;
