import React, { Component } from 'react';
// import ProjectData from './ProjectData';
import manifest from './Images/manifest.png';
import content from './Images/content.png';
import before from './Images/before.png';
import after from './Images/after.png';
import './Single.css';

class Content extends Component {
	render() {
		return (
			<div className="Content">
				<div className="container">
					<div className="row">
						<div className="col-lg-3 Content-title">
							<h2>Arrested Development Chrome Extension</h2>
							<a href="https://github.com/ArelySkywalker/Arrested-Development-Extension" target="_blank" rel="noopener noreferrer" className="App-button">View on Github</a>
						</div>
						<div className="col-lg-9 Content-text">
							<p>Arrested Developemnt is one of my all time favorite shows, and if you know me, you know I will take any open opportunity to quote the show. So I decided to build a Chrome Extension that listens to specific key words and append a quote next to it.</p>
							<p>How do we build an extension? First things first, you need to create a MANIFEST. This is where we give Google our info about the extension we want to upload and what files to listen to.</p>
							<p><img src={ manifest } alt="manifest.png" /></p>
							<p>Next, I start adding in my logic.</p>
							<p><img src={ content } alt="content.png" /></p>
							<p>I want to search the entire document, I decided to just restrict to only looking inside p tags, this helps to prevent any weird bugs in case the nav or script contains a key word I am searching for.</p>
							<p>I then make my own array of words and quotes I want to add next to the key word if found. I loop through the array and if there's a match, we use the replace function and then create the new text node to inject in the document.</p>
							<p>Voila! Simple Chrome Extension that works. This is a great/fun project to do on a Sunday morning while having coffee.</p>
							<br />
							<div className="row">
								<div className="col-lg-6">
									<p><strong>Before:</strong></p>
									<p><img src={ before } alt="before" /></p>
								</div>
								<div className="col-lg-6">
									<p><strong>After:</strong></p>
									<p><img src={ after } alt="after" /></p>
								</div>
							</div>
							<h3>Happy Hacking!</h3>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default Content;