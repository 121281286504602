import React, { Component } from 'react';
import Banner from './Banner';
import todoapp from './Images/todoapp.gif';
import './Single.css';

class Single extends Component {
	render() {
		return (				
			<div className="Single">
				<Banner id="todoapp" />
				<div className="Content">
					<div className="container">
						<div className="row">
							<div className="col-lg-3 Content-title">
								<h2>To-Do List App</h2>
								<a href="https://github.com/ArelySkywalker/toDoListApp" target="_blank" rel="noopener noreferrer" className="App-button">View on Github</a>
								<a href="/todoapp/" target="_blank" className="App-button">View App</a>
							</div>
							<div className="col-lg-9 Content-text">
								<p>Vanilla JavaScript to-do list app. A very simple to-do list app developed using: HTML, CSS, and Vanilla JavaScript. This app was developed in order to cement my knowledge of DOM manipulaiton.</p>
								<p>This is based off of <a href="https://blog.usejournal.com/develop-a-to-do-list-app-in-vanilla-javascript-95377ec370c5" target="_blank" rel="noopener noreferrer">Carlos de Costa's Medium article</a>. I read through the article and attempted to create the functions myself before looking at his code, then compared what I wrote vs what he wrote. This is a really good way for me to practice, and I would recommend anyone to do the same!</p>
								<br />
								<img src={ todoapp } alt="To-Do List App" />
								<br />
								<br />
								<p>If you're looking to practice DOM manipulaiton, a simple To-Do List app would be the perfect start! Especially if you are needing to practice for those technical interviews!</p>
								<p>Functions created:
									<ul>
										<li>Submit Form</li>
										<li>Add a Task</li>
										<li>Remove a Task</li>
										<li>Tick a Task</li>
										<li>Clear Task List</li>
									</ul>
								</p>
								<p>Before looking at my code, go ahead and try implementing these functions, if you get stuck, you can peek at my code for some guidance!</p>
								<br />
								<br />
								<h3>Happy Hacking!</h3>
							</div>
						</div>
					</div>
				</div>
			</div>			
		);
	}
}

export default Single;