import React, { Component } from 'react';
import xWing from './x-wing.png';
import tieFighter from './tie-fighter.png';

class Logo extends Component {
	render() {
		return (
			<div className="Logo">
				<a href="/">
					<img
						className="Logo-img tieFighter"
						src={ tieFighter }
						alt="ArelySkywalker"
					/>
					<img
						className="Logo-img xWing"
						src={ xWing }
						alt="ArelySkywalker"
					/>
				</a>
			</div>
		);
	}
}

export default Logo;