import React, { Component } from 'react';
import Content from './Content';

class Contact extends Component {
	render() {
		return (
			<div className="Contact">
				<Content />
			</div>
		);
	}
}

export default Contact;