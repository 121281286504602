import React, { Component } from "react";
import "./Contact.css";

class Content extends Component {
    render() {
        return (
            <div className="Content">
                <div className="container">
                    <div className="col-12">
                        <h1 className="Intro-title">
                            Arely Miramontes Rodríguez
                        </h1>
                    </div>
                </div>
                <div className="container Contact-wrap">
                    <div className="row">
                        <div className="col-lg-3 Content-title">
                            <h2>Contact</h2>
                            <a
                                href="https://github.com/ArelySkywalker"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <i className="fab fa-github"></i>
                            </a>
                            <a
                                href="https://www.linkedin.com/in/arelymiramontes/"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <i className="fab fa-linkedin-in"></i>
                            </a>
                            <a
                                href="https://twitter.com/ArelySkywalker"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <i className="fab fa-twitter"></i>
                            </a>
                            <a href="mailto:arelymts@gmail.com">
                                arelymts@gmail.com
                            </a>
                        </div>
                        <div className="col-lg-9 Content-text">
                            <p>
                                Wanna have a chat or grab a coffee? Shoot me an
                                email and get in touch!
                            </p>
                            <p>
                                It takes more than procrastinating real work by
                                fixing minor bugs to be a great software
                                engineer!
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Content;
