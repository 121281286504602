import React, { Component } from 'react';
import Banner from './Banner';
import Content from './Content';
import './Single.css';

class Single extends Component {
	render() {
		return (				
			<div className="Single">
				<Banner id="arrested" />
				<Content />
			</div>			
		);
	}
}

export default Single;