import React, { Component } from 'react';
import Addy from './Images/addy_awards.jpg';
import BoardGames from './Images/boardgames.jpg';
import LYS from './Images/lys.jpg';
import LogicalJourney from './Images/LogicalJourney.jpg';
import Pandemic1 from './Images/pandemic_1.jpg';
import Pandemic2 from './Images/pandemic_2.jpg';
import PixelPicnic1 from './Images/pixelpicnic_1.jpg';
import PixelPicnic2 from './Images/pixelpicnic_2.jpg';
import PixelPicnic3 from './Images/pixelpicnic_3.jpg';
import PixelPicnic4 from './Images/pixelpicnic_4.jpg';
import Splendor1 from './Images/splendor_1.jpg';
import Splendor2 from './Images/splendor_2.jpg';
import Amazon from './Images/amazon_2.jpg';
import './About.css';

class Content extends Component {
	render() {
		return (
			<div className="Content">
				<div className="container">
					<div className="row">
						<div className="col-lg-3 Content-title">
							<h2>About</h2>
						</div>
						<div className="col-lg-9 Content-text">
							<p>I am a passionate developer with a love for mathematics, coding, and UI/UX. With
experience working on custom web applications, I strive to create an impression on client
projects to be more efficient, creative, productive and fun.</p>
							<p>Along with my development work, I actively support inclusion and diversity in the S.T.E.M. industry through various community events, meetups, groups, and organizations.</p>
						</div>
					</div>
					<div className="row">
						<div className="col-lg-3 Content-title">
							<h2>Experience</h2>
						</div>
						<div className="col-lg-9 Content-text">
							<ul>
								<li><strong>Frontend Software Engineer</strong> - Amazon Web Services
									<ul>
										<li><small>New York City</small></li>
										<li><small>2019 - Present</small></li>
				
								</ul>
								</li>
								<li><strong>Full Stack Developer</strong> - Powderkeg Web Design
									<ul>
										<li><small>Madison, Wisconsin</small></li>
										<li><small>2017 - 2019</small></li>
									</ul>
								</li>
								<li><strong>Lead Web Developer</strong> - Distillery Marketing & Design
									<ul>
										<li><small>Madison, Wisconsin</small></li>
										<li><small>2016 - 2017</small></li>
									</ul>
								</li>
								<li><strong>Web Developer Intern</strong> - Heibing
									<ul>
										<li><small>Madison, Wisconsin</small></li>
										<li><small>Summer 2016</small></li>
									</ul>
								</li>
								<li><strong>Computer Consultant/Technical Analyst</strong> - University of New Mexico
									<ul>
										<li><small>Albuquerque, New Mexico</small></li>
										<li><small>2014 - 2016</small></li>
									</ul>
								</li>
							</ul>
						</div>
					</div>
					<div className="row">
						<div className="col-lg-3 Content-title">
							<h2>Education</h2>
						</div>
						<div className="col-lg-9 Content-text">
							<p>I graduated from the University of New Mexico with a <strong>Bachelor’s of Science in Computational Mathematics & Computer Science</strong>. I’m a simple girl, I love mathematics and coding – so I got a degree with the best of both worlds. During my time here I was a member of the UNM Society of Women Engineers and the M.E.S.A. Club Alumni (Mathematics, Engineering, and Science Achievement) where I volunteer as a judge for the state-wide science competition.</p>
							<p>Along with my Bachelor’s degree, I also obtained an <strong>Associates Degree in General Sciences</strong> from the Eastern New Mexico University – Roswell. I was a member of of Phi Theta Kappa Honor’s Society, Dean’s List, and Presidential Scholar. I was the student graduation speaker of Fall Class of  2012.</p>
						</div>
					</div>
					<div className="row">
						<div className="col-lg-3 Content-title">
							<h2>Community Envolvement</h2>
						</div>
						<div className="col-lg-9 Content-text">
							<p>I actively participate with multiple groups and organizations to engage with fellow peers in my area of Interest. My efforts focus on delivering career development opportunities, mentor-ship, and increasing student awareness and engagement in STEM.</p>
							<ul>
								<li>Amazon Women in Engineering - <strong>Member</strong></li>
								<li>Techqueria - <strong>Member</strong></li>
								<li>Women Who Code NYC - <strong>Member</strong></li>
								<li>Women in Software Engineering NYC - <strong>Mentor</strong></li>
								<li>Latino Youth Summit - <strong>Instructor</strong></li>
								<li>New Mexico M.E.S.A. - <strong>Judge & Mentor</strong></li>
							</ul>
						</div>
					</div>
					<div className="row">
						<div className="col-12">
							<div id="aboutMeCarousel" className="carousel slide carousel-fade" data-ride="carousel">
								<ol className="carousel-indicators">
									<li data-target="#aboutMeCarousel" data-slide-to="0" className="active"></li>
									<li data-target="#aboutMeCarousel" data-slide-to="1"></li>
									<li data-target="#aboutMeCarousel" data-slide-to="2"></li>
									<li data-target="#aboutMeCarousel" data-slide-to="3"></li>
									<li data-target="#aboutMeCarousel" data-slide-to="4"></li>
									<li data-target="#aboutMeCarousel" data-slide-to="5"></li>
									<li data-target="#aboutMeCarousel" data-slide-to="6"></li>
									<li data-target="#aboutMeCarousel" data-slide-to="7"></li>
									<li data-target="#aboutMeCarousel" data-slide-to="8"></li>
									<li data-target="#aboutMeCarousel" data-slide-to="9"></li>
									<li data-target="#aboutMeCarousel" data-slide-to="10"></li>
									<li data-target="#aboutMeCarousel" data-slide-to="11"></li>
									<li data-target="#aboutMeCarousel" data-slide-to="12"></li>
								</ol>
								<div className="carousel-inner">
									<div className="carousel-item active">
										<img src={ Amazon } alt="Amazon Web Services" />
										<div className="carousel-caption d-none d-md-block">
											<h5>Amazon Web Services</h5>
											<p>I accepted an offer to work for Amazon Web Services! Huge milestone in my career.</p>
										</div>
									</div>
									<div className="carousel-item">
										<img src={ Addy } alt="Addy Awards 2019" />
										<div className="carousel-caption d-none d-md-block">
											<h5>Addy Awards 2019</h5>
											<p>Powderkeg Web Design won 13 awards for ourd websites where 2 of them were mine.</p>
										</div>
									</div>
									<div className="carousel-item">
										<img src={ LYS } alt="Latino Youth Summit" />
										<div className="carousel-caption d-none d-md-block">
											<h5>Latino Youth Summit</h5>
											<p>I volunteer as an instructor to teach Computer Science. Latino Youth Summit is two days courses to get middle school students excited about college.</p>
										</div>
									</div>
									<div className="carousel-item">
										<img src={ LogicalJourney } alt="Logical Journey" />
										<div className="carousel-caption d-none d-md-block">
											<h5>Extra Life Charity</h5>
											<p>I participated in a 48 hour non stop video game run with a local video game streaming group. We achieved our fundraiser goal and raised $3,525 for the Madison's Children Hospital.</p>
										</div>
									</div>
									<div className="carousel-item">
										<img src={ BoardGames } alt="Board Games" />
										<div className="carousel-caption d-none d-md-block">
											<h5>I love board games!</h5>
											<p>When I am not coding or playing video games, you can find me playing some board games with my friends!</p>
										</div>
									</div>
									<div className="carousel-item">
										<img src={ Pandemic1 } alt="Pandemic Tournament" />
										<div className="carousel-caption d-none d-md-block">
											<h5>1st Winner for the Pandemic Tournament</h5>
										</div>
									</div>
									<div className="carousel-item">
										<img src={ Pandemic2 } alt="Pandemic Tournament" />
										<div className="carousel-caption d-none d-md-block">
											<h5>1st Winner for the Pandemic Tournament</h5>
											<p>As my frist prize, I won a Pandemic Expansion!</p>
										</div>
									</div>
									<div className="carousel-item">
										<img src={ Splendor1 } alt="Splendor Tournament" />
										<div className="carousel-caption d-none d-md-block">
											<h5>1st Winner for the Splendor Tournament</h5>
										</div>
									</div>
									<div className="carousel-item">
										<img src={ Splendor2 } alt="Splendor Tournament" />
										<div className="carousel-caption d-none d-md-block">
											<h5>1st Winner for the Splendor Tournament</h5>
											<p>As my first prize, I won the games Arena and Pixel Battles!</p>
										</div>
									</div>
									<div className="carousel-item">
										<img src={ PixelPicnic1 } alt="Pixel Picnic" />
										<div className="carousel-caption d-none d-md-block">
											<h5>Pixel Picnic's First Game Jam</h5>
											<p>This was our first Game Jam where we made our game RootStock!</p>
										</div>
									</div>
									<div className="carousel-item">
										<img src={ PixelPicnic2 } alt="Pixel Picnic" />
										<div className="carousel-caption d-none d-md-block">
											<h5>RootStock</h5>
											<p>Here we showcased our first game we built at a local game event, Indie Arcade.</p>
										</div>
									</div>
									<div className="carousel-item">
										<img src={ PixelPicnic3 } alt="Pixel Picnic" />
										<div className="carousel-caption d-none d-md-block">
											<h5>RootStock</h5>
											<p>Here we showcased our first game we built at a local game event, Indie Arcade.</p>
										</div>
									</div>

									<div className="carousel-item">
										<img src={ PixelPicnic4 } alt="Pixel Picnic" />
										<div className="carousel-caption d-none d-md-block">
											<h5>RootStock</h5>
											<p>Here we showcased our first game we built at a local game event, Indie Arcade.</p>
										</div>
									</div>

								</div>
								<a className="carousel-control-prev" href="#aboutMeCarousel" role="button" data-slide="prev">
									<span className="carousel-control-prev-icon" aria-hidden="true"></span>
									<span className="sr-only">Previous</span>
								</a>
								<a className="carousel-control-next" href="#aboutMeCarousel" role="button" data-slide="next">
									<span className="carousel-control-next-icon" aria-hidden="true"></span>
									<span className="sr-only">Next</span>
								</a>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default Content;