import React, { Component } from 'react';
import './Footer.css';

class Footer extends Component {
	render() {
		return (
			<div className="Footer">
				<div className="container">
					<div className="row">
						<div className="col-md-6 Footer-copyright">
							<p>© 2019 Arely Miramontes Rodríguez. All Rights Reserved.</p>
						</div>
						<div className="col-md-6 Footer-social">
							<a href="https://github.com/ArelySkywalker" target="_blank" rel="noopener noreferrer" ><i className="fab fa-github"></i></a>
							<a href="https://www.linkedin.com/in/arelymiramontes/" target="_blank" rel="noopener noreferrer" ><i className="fab fa-linkedin-in"></i></a>
							<a href="https://twitter.com/ArelySkywalker" target="_blank" rel="noopener noreferrer" ><i className="fab fa-twitter"></i></a>
						</div>
					</div>
					<div className="row">
						<div className="col-12 Footer-built">
							<p>Built with <a href="https://reactjs.org/" target="_blank" rel="noopener noreferrer" >React</a> using <i className="fas fa-heart"></i></p>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default Footer;