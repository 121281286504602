import React, { Component } from "react";
import Fade from "react-reveal/Fade";
import "./Home.css";
import javascriptIcon from "./Images/js.png";
import reactIcon from "./Images/react.png";
import reduxIcon from "./Images/redux.png";
import dynamodbIcon from "./Images/dynamodb.png";
import d3jsIcon from "./Images/d3.png";
import nodejsIcon from "./Images/nodejs.png";
import cypressIcon from "./Images/cypress.png";
import gitIcon from "./Images/git.png";

class Skills extends Component {
    static defaultProps = {
        mySkills: [
            { name: "JavaScript", icon: javascriptIcon },
            { name: "React", icon: reactIcon },
            { name: "Redux", icon: reduxIcon },
            { name: "DynamoDB", icon: dynamodbIcon },
            { name: "D3.js", icon: d3jsIcon },
            { name: "Node JS", icon: nodejsIcon },
            { name: "Cypress", icon: cypressIcon },
            { name: "Git", icon: gitIcon },
        ],
    };
    render() {
        return (
            <Fade bottom>
                <div name="myStack" className="Skills" id="My-Stack">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 Skills-title">
                                <h2 className="">
                                    My Stack{" "}
                                    <span role="img" aria-label="pancake stack">
                                        🥞
                                    </span>
                                </h2>
                                <p>
                                    It's my passion to learn new technologies
                                    everyday to expand my knowdlege.
                                </p>
                            </div>
                        </div>
                        <div className="row justify-content-md-center">
                            {this.props.mySkills.map((skill, index) => {
                                return (
                                    <div
                                        className="Skills-item col-md-3 col-sm-4 col-6"
                                        key={index}
                                    >
                                        <img
                                            src={skill.icon}
                                            alt={skill.name}
                                        />
                                        <h5>{skill.name}</h5>
                                    </div>
                                );
                            })}
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <h2 className="Skills-bottom-title">
                                    And More!
                                </h2>
                            </div>
                        </div>
                    </div>
                </div>
            </Fade>
        );
    }
}

export default Skills;
