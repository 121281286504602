import React, { Component } from 'react';

const str = window.location.href;
var base = new String(str).substring(str.lastIndexOf('/') + 1); 
if(base.lastIndexOf(".") !== -1) {
	base = base.substring(0, base.lastIndexOf("."));
}

class NavMobile extends Component {
	static defaultProps = {
		navItems: [
			{ title: 'Home', slug: '' },
			{ title: 'About', slug: 'about' },
			{ title: 'Projects', slug: 'projects' },
			{ title: 'Contact', slug: 'contact' }
		]
	};
	
	render() {
		return (
			<div className="NavMobile">
                <nav className="navbar navbar-light amber lighten-4 mb-4">
                    <a className="navbar-brand" href="void()"> </a>
                    <button className="navbar-toggler first-button" type="button" data-toggle="collapse" data-target="#navbarSupportedContent20"
                        aria-controls="navbarSupportedContent20" aria-expanded="false" aria-label="Toggle navigation">
                        <div className="animated-icon1">
                            <i className="fas fa-bars"></i>
                        </div>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarSupportedContent20">

                        <ul className="navbar-nav mr-auto">
                            {this.props.navItems.map(
                                (item, index) => {
                                    return (
                                        <li className={`nav-item${item.slug === base ? " active" : ""}`} id={ `index-${index}` } key={ index }>
                                            <a className="nav-link" href={ `/${ item.slug }` }>{ item.title }</a>
                                        </li>
                                    )
                                }
                            )}
                        </ul>
                    </div>
                </nav>
			</div>
		);
	}
}

export default NavMobile;