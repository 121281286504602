import React, { Component } from "react";
import Fade from "react-reveal/Fade";
import tieFigher from "./Images/tie-fighter-2.png";
import "./Home.css";

class Intro extends Component {
    render() {
        return (
            <Fade bottom>
                <div className="Intro">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <img src={tieFigher} alt="TIE Fighter" />
                                <h1 className="Intro-title">
                                    Arely Miramontes Rodríguez
                                </h1>
                                <h2 className="Intro-description">
                                    I'm a Frontend Software Engineer at Amazon
                                    Web Services (AWS).
                                </h2>
                                <h4>
                                    Experienced software engineer with over 8
                                    years in full-stack development,
                                    specializing in creating scalable,
                                    responsive, single-page applications with a
                                    passion for UI/UX and mathematics.
                                </h4>
                            </div>
                        </div>
                    </div>
                </div>
            </Fade>
        );
    }
}

export default Intro;
