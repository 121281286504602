import React, { Component } from 'react';
import Nav from './Nav';
import NavMobile from './NavMobile';
import Logo from './Logo';
import './Header.css';

const str = window.location.href;
var base = new String(str).substring(str.lastIndexOf('/') + 1); 
if(base.lastIndexOf(".") !== -1) {
	base = base.substring(0, base.lastIndexOf("."));
}

class Header extends Component {
	render() {
		return (
			<div className={`Header ${base ? "" : "home"}`}>
				<Logo />
				<NavMobile />
				<Nav />
			</div>
		);
	}
}

export default Header;