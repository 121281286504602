import React, { Component } from 'react';
import './Projects.css';

class Banner extends Component {
	render() {
		return (
			<div className="Banner">
				<div className="Banner-bg">
					<h1 className="Banner-title">Arely Miramontes Rodríguez</h1>
				</div>
			</div>
		);
	}
}

export default Banner;