import React from 'react';

const str = window.location.href;
var base = new String(str).substring(str.lastIndexOf('/') + 1); 
if(base.lastIndexOf(".") !== -1) {
	base = base.substring(0, base.lastIndexOf("."));
}

const navItems = [
	{ title: 'Home', slug: '' },
	{ title: 'About', slug: 'about' },
	{ title: 'Projects', slug: 'projects' },
	{ title: 'Contact', slug: 'contact' }
];	

const Nav = ()=>{
	return(
	
			<nav className={'Nav-Wrap'}>
				<div className="Nav">
					<ul className="Nav-items">
						{navItems.map(
							(item, index) => {
								return (
									<li className={`Nav-item${item.slug === base ? " current" : ""}`} id={ `index-${index}` } key={ index }>
										<a href={ `/${ item.slug }` }>{ item.title }</a>
									</li>
								)
							}
						)}
					</ul>
				</div>
			</nav>
	);
}


export default Nav;