import React, { Component } from 'react';

class Resume extends Component {
	render() {
		return (
			<div className="Resume">
				<h1>Resume Page!</h1>
			</div>
		);
	}
}

export default Resume;