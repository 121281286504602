// Import our Routers
import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Home from './Components/Home/Home';
import About from './Components/About/About';
import Resume from './Components/Resume/Resume';
import Projects from './Components/Projects/Projects';
import Single from './Components/Projects/Single/Single';
import Cocktail from './Components/Projects/Single/Cocktail';
import ToDoListApp from './Components/Projects/Single/ToDoListApp';
import Contact from './Components/Contact/Contact';

const Main = () => (
	<BrowserRouter>
		<Switch>
			<Route exact path="/" component={ Home } />
			<Route path="/about" component={ About } />
			<Route path="/resume" component={ Resume } />
			<Route path="/projects" component={ Projects } />
			<Route path="/ad-extension" component={ Single } />
			<Route path="/cocktail" component={ Cocktail } />
			<Route path="/contact" component={ Contact } />
			<Route path="/todolistapp" component={ ToDoListApp } />
		</Switch>
	</BrowserRouter>
)

export default Main;