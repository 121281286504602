import React from 'react';
import Header from './Components/Header/Header';
import Footer from './Components/Footer/Footer';
import Main from './Main';
import './App.css';

function App() {
	return (
		<div className="App">
			<header className="App-header">
				<Header />
			</header>
			<div className="MainContent">
				<Main />
			</div>
			<footer className="App-footer">
				<Footer />
			</footer>
		</div>
	);
}

export default App;
