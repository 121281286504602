import React, { Component } from "react";
import Fade from "react-reveal/Fade";
import "./Home.css";

class Skills extends Component {
    render() {
        return (
            <Fade bottom>
                <div name="featProject" className="Project">
                    <div className="container">
                        <div className="row cocktail">
                            <div className="col-12 Project-title">
                                <h2>Featured Project</h2>
                            </div>
                            <a
                                href="/cocktail"
                                className="col-12"
                                text-decoration="none"
                            >
                                <div className="Project-image"></div>
                            </a>
                            <a
                                href="/cocktail"
                                className="col-12 Project-meta"
                                text-decoration="none"
                            >
                                <h3>Cocktail Recipe Finder Web App</h3>
                                <br />
                                <p>
                                    A dynamic web application built to help
                                    users discover and filter cocktail recipes
                                    with ease. The app integrates the CocktailDB
                                    API to provide users with a wide range of
                                    drink options based on their preferences.
                                </p>
                                <span className="App-button">View Project</span>
                            </a>
                        </div>
                    </div>
                </div>
            </Fade>
        );
    }
}

export default Skills;
