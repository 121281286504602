import React, { Component } from 'react';
import './Single.css';

class Banner extends Component {
	render() {
		return (
			<div className="Banner">
				<div className="container">
					<div className="row">
						<div className="col-12">
							<div className={ `Banner-bg ${ this.props.id }` }></div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default Banner;